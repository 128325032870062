import { Avatar, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import DarkBtn from '../Buttons/DarkBtn';
import { images } from '../../../assets';

const DirectoryFilterCard = ({ directory }) => {
  return (
    <div className="directory_filter_card">
      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xs={8} sm={4.5}>
          <Stack direction="row" spacing={{ xs: 2, sm: 3 }} alignItems="center">
            <Avatar src={directory.directory_logo || images.defaultAvatar} className="avatar_img" />

            <div>
              <div className="font10px">{directory.directory_name}</div>
              <Stack direction="row" spacing={1} alignItems="center">
                {directory.directory_trusted ? (
                  <img src={images.cosimoIcon} alt="Cosimo Icon" className="cosimo_icon" />
                ) : null}
                <div className="font5px">{directory.directory_trusted ? 'Trusted Partner' : ''}</div>
              </Stack>
            </div>
          </Stack>
        </Grid>

        <Grid item xs={4} sm={2.5}>
          <div className="img_directory_logo">
            <img src={images.directoryLoction} alt="" className="location_img" />
            <div className="font10px">{directory.location.location_name}</div>
          </div>
        </Grid>
        <Grid item xs={8} sm={2.5} className="mobileshow buffering_section" />
        <Grid item xs={4} sm={2} className="buffering_section">
          <div className="img_directory_logo">
            <img src={directory.offering.offering_logo} alt="" className="location_img" />
            <div className="font10px">{directory.offering.offering_name}</div>
          </div>
        </Grid>
        {/* </Stack> */}
        <Grid item xs={3} sx={{ background: 'transparent' }} className="mobilehide">
          <Stack direction="row" alignItems="center" className="width100 mobilehide">
            <DarkBtn title="Visit Website" onClick={() => window.open(directory.directory_website_url, '_blank')} />
          </Stack>
        </Grid>
      </Grid>
      <div className="mobileshow tabhide desktophide" style={{ marginTop: '20px' }}>
        <DarkBtn title="Visit Website" onClick={() => window.open(directory.directory_website_url, '_blank')} />
      </div>
    </div>
  );
};

export default DirectoryFilterCard;
