import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Skeleton, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useEffect } from 'react';
import AxiosAll from '../../../services/axiosAll';

// eslint-disable-next-line react/function-component-definition
export default function DirectoryFilter({ filterstate, setFilterState }) {
  const { userDetails } = useSelector((state) => state.user);
  const [loading, setLoading] = React.useState(false);

  const [filters, setFilters] = React.useState({
    locations: [],
    offerings: [],
    trusted: [],
  });

  const getCards = async () => {
    setLoading(true);
    try {
      const response = await AxiosAll(
        'get',
        'artist/directories-filters?type=locations,offerings,trusted',
        '',
        userDetails.token,
      );
      // const data = res?.data?.data;
      const { locations, offerings, trusted } = response.data.data;
      setFilters({
        locations: locations.map((location) => ({ ...location, checked: false })),
        offerings: offerings.map((offering) => ({ ...offering, checked: false })),
        trusted: trusted.map((trust) => ({ ...trust, checked: false })),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCheckedState = (items, id) =>
    items.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item));

  const getCheckedIds = (items) => items.filter((item) => item.checked).map((item) => item.id);

  const changeFilterState = (state, id) => {
    setFilters((prevFilters) => {
      const newState = {
        ...prevFilters,
        [state]: updateCheckedState(prevFilters[state], id),
      };

      const updatedFilterState = {
        locations: getCheckedIds(newState.locations),
        offerings: getCheckedIds(newState.offerings),
        trusted: getCheckedIds(newState.trusted),
      };

      setFilterState(updatedFilterState);

      return newState;
    });
  };

  const clearFilters = () => {
    setFilters((prevFilters) => ({
      locations: prevFilters.locations.map((location) => ({ ...location, checked: false })),
      offerings: prevFilters.offerings.map((offering) => ({ ...offering, checked: false })),
      trusted: prevFilters.trusted.map((trusted) => ({ ...trusted, checked: false })),
    }));
    setFilterState({
      locations: [],
      offerings: [],
      trusted: [],
    });
  };

  return (
    <div className="directory_filter_screen">
      <Stack direction="row" justifyContent="space-between">
        <div className="font14px filter_text ">Filter</div>
        <div className="font14px filter_text clear_filter" tabIndex={0} role="button" onClick={clearFilters}>
          Clear
        </div>
      </Stack>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="font12px"
        >
          Location
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </div>
          ) : (
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                {filters.locations.map((location) => (
                  <FormControlLabel
                    key={location.id}
                    value={location.id}
                    control={
                      <Checkbox
                        checked={location.checked}
                        onChange={() => changeFilterState('locations', location.id)}
                      />
                    }
                    label={location.location_name}
                    labelPlacement="start"
                  />
                ))}
              </FormGroup>
            </FormControl>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Offering Filter */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className="font12px"
        >
          Offering
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </div>
          ) : (
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                {filters.offerings.map((offering) => (
                  <FormControlLabel
                    key={offering.id}
                    value={offering.id}
                    control={
                      <Checkbox
                        checked={offering.checked}
                        onChange={() => changeFilterState('offerings', offering.id)}
                      />
                    }
                    label={offering.offering_name}
                    labelPlacement="start"
                  />
                ))}
              </FormGroup>
            </FormControl>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Trusted Filter */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          className="font12px"
        >
          Trusted
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </div>
          ) : (
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                {filters.trusted.map((trusted) => (
                  <FormControlLabel
                    key={trusted.id}
                    value={trusted.id}
                    control={
                      <Checkbox checked={trusted.checked} onChange={() => changeFilterState('trusted', trusted.id)} />
                    }
                    label={trusted.name}
                    labelPlacement="start"
                  />
                ))}
              </FormGroup>
            </FormControl>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
