/* eslint-disable import/no-useless-path-segments */
export const images = {
  topBarImg: require('./images/header/topbarimg.jpg'),
  mainLogo: require('./images/header/mainlogo.png'),
  searchIcon: require('./images/header/searchicon.png'),
  sidebarIcon: require('./images/header/sidebaricon.png'),
  cartIcon: require('./images/header/carticon.png'),
  visaIcon: require('./images/footer/visa.png'),
  masterIcon: require('./images/footer/mastercard.png'),
  payIcon: require('./images/footer/pay.png'),
  gpayIcon: require('./images/footer/gpay.png'),
  FACEBOOK: require('./images/footer/footer-face-book.png'),
  INSTAGRAM: require('./images/footer/footer-instagram.png'),
  LINKEDIN: require('./images/footer/footer-linkedin.png'),
  PINTERESR: require('./images/footer/pinterest.png'),
  YOUTUBE: require('./images/footer/footer-youtube.png'),
  TWITTER: require('./images/footer/twitter.png'),
  google: require('./images/home/google.png'),
  apple: require('./images/home/apple.png'),
  rightIcon: require('./images/home/righticon.png'),
  locationRight: require('./images/home/artistssign.png'),
  checked: require('./images/icons/checked.png'),
  pending: require('./images/icons/pending.png'),
  checkedNew: require('./images/icons/checkgreen.png'),
  lockBlack: require('./images/icons/lockBlack.png'),
  pinktick: require('./images/icons/pinktick.png'),
  pendingNew: require('./images/icons/pendingNew.png'),
  map: require('./images/productdetail/map.png'),
  imagePlaceholder: require('./images/image-placeholder.png'),
  facebook: require('./images/icons/facebook.png'),
  pinterest: require('./images/icons/pinterest.png'),
  imprimo: require('./images/icons/imprimo.png'),
  tikTok: require('./images/icons/tik-tok.png'),
  linkedin: require('./images/icons/linkedin.png'),
  twitter: require('./images/icons/twitter.png'),
  instagram: require('./images/icons/instagram.png'),
  addFile: require('./images/icons/add-file.png'),
  addImage: require('./images/icons/add-image.png'),
  eyebal: require('./images/icons/eyebal.png'),
  download: require('./images/icons/download.png'),
  send: require('./images/icons/send.png'),
  kidWithArt: require('./images/home/kidwithart.png'),
  action: require('./images/notification/action.png'),
  done: require('./images/notification/done.png'),
  PDFLogo: require('./images/home/cosimo-pdf-logo.png'),
  card: require('../assets/images/card/card.webp'),
  visa: require('../assets/images/card/visa.webp'),
  mastercard: require('../assets/images/card/mastercard.webp'),
  amex: require('../assets/images/card/amex.webp'),
  discover: require('../assets/images/card/discover.webp'),
  jcb: require('../assets/images/card/jcb.webp'),
  dinner_club: require('../assets/images/card/dinner-club.webp'),
  union_pay: require('../assets/images/card/union-pay.webp'),
  Ellips: require('../assets/images/productdetail/Ellipse.png'),
  Info: require('../assets/images/icons/info.png'),
  avtarPlaceholder: require('../assets/images/avtar-placholder.png'),
  celebrateImg: require('../assets/images/home/celebrate.png'),
  createAccount: require('./images/home/42.png'),
  works: require('./images/home/43.png'),
  academy: require('./images/home/44.png'),
  searchNew: require('./images/home/36.png'),
  scale: require('./images/home/37.png'),
  budget: require('./images/home/38.png'),
  locationNew: require('./images/home/location1.png'),
  inspired: require('./images/home/39.png'),
  small: require('./images/home/small.png'),
  medium: require('./images/home/medium.png'),
  large: require('./images/home/large.png'),
  extraLarge: require('./images/home/extra-large.png'),
  curated: require('./images/home/40.png'),
  star: require('./images/home/star.png'),
  aeroplane: require('./images/home/aeroplane.png'),
  diamond: require('./images/home/diamond.png'),
  palette: require('./images/home/palette.png'),
  uploadFile: require('./images/upload_file.png'),
  blacktick: require('./images/notification/EllipseBlack.png'),
  lockPng: require('./images/lock.png'),
  directoryLoction: require('./images/icons/location_new.png'),
  cartNew: require('./images/icons/cart_new.png'),
  cosimoIcon: require('./images/icons/logo512.png'),
  filterIcon: require('./images/icons/filter.png'),

  // profileIcon: require('../images/home/profile-icon.jpeg'),
};
